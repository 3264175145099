import React from 'react'
import Footer from './Footer'
import Navbar from './Navbar'

const Terms = () => {
    return (
        <div>
            <Navbar />
            <div className='terms_of_use_container'>
                <div className='red_bg_terms' />
                <div className="terms_of_use_content shadow-lg">
                    <h2 className='pb_4'>Terms of Use</h2>
                    <p>
                        <h4>
                            NO REFUNDS
                        </h4>
                        <div>
                            Except as expressly provided herein, Kids Multicultural World LLC Voting maintains a strict no-refund policy on ALL VOTING SYSTEM fees. 
                        </div>
                    </p>
                    <p>
                        <h4>
                            LIMITATION OF LIABILITY
                        </h4>
                        <div>
                            <p>
                                Except as expressly set forth herein, Kids Multicultural World LLC will not be liable for any damages incurred in connection with the use of VOTING SYSTEM. This includes any direct, indirect, consequential or incidental damages that may arise from the use of VOTING SYSTEM, the failure of VOTING SYSTEM, or the termination of the access to VOTING SYSTEM. This limitation of liability will also apply to any loss of data, information or content through failure of VOTING SYSTEM or interruption of transmission. KIDS MULTICULTURAL WORLD Voting will not be liable for any harm or loss arising from unauthorized access to data, information or transmission, including, but not limited to tangible or intangible loss of revenues, profits, data or information.
                            </p>
                            <p>
                                Except as expressly set forth herein, you agree that KMW is not liable for any damages arising from the interruption, cancellation or suspension of VOTING SYSTEM, regardless of whether the failure of VOTING SYSTEM is announced, justified, or negligent.
                            </p>
                        </div>
                    </p>
                    <p>
                        <h4>
                            GENERAL DISCLAIMER
                        </h4>
                        <div>
                            <p>
                                You understand that all data, information or other material collected by you through KMW SYSTEM is your sole responsibility. KMW Voting is not responsible for any loss of data or harm done to your computer(s), systems or other equipment in conjunction with use of VOTING SYSTEM. You understand and agree that use of VOTING SYSTEM is done at your own risk and discretion.
                            </p>
                        </div>
                    </p>
                    <p>
                        <h4>
                            INDEMNIFICATION
                        </h4>
                        <div>
                            <p>
                                You agree that KIDS MULTICULTURAL WORLD Voting will not be held responsible for any claims, damages, demands or fees arising out of any breach by you of this Agreement, the Privacy Policy or infringements on the rights of any third parties related to your use of VOTING SYSTEM. You also agree to KMW Voting and its directors, employees, agents, and partners for any and all claims that may arise.
                            </p>
                        </div>
                    </p>
                    <p>
                        <h4>
                            DATA STORAGE
                        </h4>
                        <div>
                            <p>
                                You understand and agree that all data collected using VOTING SYSTEM will not be stored on KMW Voting servers. KMW assumes no responsibility for your deletion of, or your failure to store any data or other information on VOTING SYSTEM.
                            </p>
                        </div>
                    </p>
                    <p>
                        <h4>
                            WEBSITES OR EMAIL UTILIZING VOTING SYSTEM
                        </h4>
                        <div>
                            <p>
                                KMW Voting does not review or monitor any user websites or email messages that utilize or link to VOTING SYSTEM and is not responsible for the content of any such websites or email messages.
                            </p>
                        </div>
                    </p>
                    <p>
                        <h4>
                            YOUR CONDUCT
                        </h4>
                        <div>
                            <p>
                                You agree to abide by all applicable laws and regulations in your use of KMW VOTING SYSTEM, and you agree not to interfere with the use and enjoyment of VOTING SYSTEM by other users. You agree to be solely responsible for the actions and the contents of your use of VOTING SYSTEM.
                            </p>
                            <p>
                                <h5>
                                    You agree:
                                </h5>
                                <ol>
                                    <li>
                                        Not to use VOTING SYSTEM for illegal purposes;
                                    </li>
                                    <li>
                                        Not to use VOTING SYSTEM for chain letters, junk mail, unlawful “spamming” solicitations (commercial or otherwise) or unlawful bulk communications of any kind, and
                                    </li>
                                    <li>
                                        Not to use VOTING SYSTEM to send an excessive number of communications to the same recipient.
                                    </li>
                                </ol>
                            </p>
                        </div>
                    </p>
                    <p>
                        You agree not to post, promote or transmit through VOTING SYSTEM any unlawful, harassing, libellous, abusive, threatening, harmful, vulgar, obscene, hateful, racially, ethnically or otherwise objectionable material of any kind or nature. You further agree not to transmit or post any material that encourages conduct that could constitute a criminal offence, give rise to civil liability or otherwise violate any applicable law or regulation.KMW Voting may, at its sole discretion, immediately terminate your access to VOTING SYSTEM should your conduct fail to conform to this Agreement. NO REFUND. Votes are final. 
                    </p>
                    <p>
                        All claims or disputes in any way related to this policy or voting (non refundable) fees shall be governed by the laws of Illinois.
                    </p>
                    <p>
                        Where Lawsuits May be Brought
                        Subject to the following policy or last  paragraphs, any and all lawsuits in any way related to this policy, shall be brought, heard, and decided only in a state or federal court located in Illinois.
                    </p>
                </div>
            </div>
            <Footer />
        </div>
        
    )
}

export default Terms