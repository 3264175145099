
export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST"
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS"
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL"

export const USER_LOGOUT = "USER_LOGOUT"
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS"
export const USER_LOGOUT_FAIL = "USER_LOGOUT_FAIL"

export const USER_PROFILE_RESET = "USER_PROFILE_RESET"

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST"
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS"
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL"

export const NEWS_LETTER_REQUEST = "NEWS_LETTER_REQUEST"
export const NEWS_LETTER_SUCCESS = "NEWS_LETTER_SUCCESS"
export const NEWS_LETTER_FAIL = "NEWS_LETTER_FAIL"

export const KIDS_VIEW_REQUEST = "KIDS_VIEW_REQUEST"
export const KIDS_VIEW_SUCCESS = "KIDS_VIEW_SUCCESS"
export const KIDS_VIEW_FAIL = "KIDS_VIEW_FAIL"

export const KIDS_DETAILS_REQUEST = "KIDS_DETAILS_REQUEST"
export const KIDS_DETAILS_SUCCESS = "KIDS_DETAILS_SUCCESS"
export const KIDS_DETAILS_FAIL = "KIDS_DETAILS_FAIL"
export const KIDS_DETAILS_RESET = "KIDS_DETAILS_RESET"

export const DEL_IMG_REQUEST = "DEL_IMG_REQUEST"
export const DEL_IMG_SUCCESS = "DEL_IMG_SUCCESS"
export const DEL_IMG_FAIL = "DEL_IMG_FAIL"
export const DEL_IMG_RESET = "DEL_IMG_RESET"

export const HANDLE_EDIT_REQUEST = "HANDLE_EDIT_REQUEST"
export const HANDLE_EDIT_SUCCESS = "HANDLE_EDIT_SUCCESS"
export const HANDLE_EDIT_FAIL = "HANDLE_EDIT_FAIL"
export const HANDLE_EDIT_RESET = "HANDLE_EDIT_RESET"

export const SHOP_REQUEST = "SHOP_REQUEST"
export const SHOP_SUCCESS = "SHOP_SUCCESS"
export const SHOP_FAIL = "SHOP_FAIL"

export const SHOP_DETAIL_REQUEST = "SHOP_DETAIL_REQUEST"
export const SHOP_DETAIL_SUCCESS = "SHOP_DETAIL_SUCCESS"
export const SHOP_DETAIL_FAIL = "SHOP_DETAIL_FAIL"

export const EVENT_REQUEST = "EVENT_REQUEST"
export const EVENT_SUCCESS = "EVENT_SUCCESS"
export const EVENT_FAIL = "EVENT_FAIL"

export const EVENT_DETAIL_REQUEST = "EVENT_DETAIL_REQUEST"
export const EVENT_DETAIL_SUCCESS = "EVENT_DETAIL_SUCCESS"
export const EVENT_DETAIL_FAIL = "EVENT_DETAIL_FAIL"

export const ADD_CART_REQUEST = "ADD_CART_REQUEST"
export const ADD_CART_SUCCESS = "ADD_CART_SUCCESS"
export const ADD_CART_FAIL = "ADD_CART_FAIL"

export const DISPLAY_MAGAZINE_REQUEST = "DISPLAY_MAGAZINE_REQUEST"
export const DISPLAY_MAGAZINE_SUCCESS = "DISPLAY_MAGAZINE_SUCCESS"
export const DISPLAY_MAGAZINE_FAIL = "DISPLAY_MAGAZINE_FAIL"

export const CONTEST_REQUEST = "CONTEST_REQUEST"
export const CONTEST_SUCCESS = "CONTEST_SUCCESS"
export const CONTEST_FAIL = "CONTEST_FAIL"

export const CONTESTANT_REQUEST = "CONTESTANT_REQUEST"
export const CONTESTANT_SUCCESS = "CONTESTANT_SUCCESS"
export const CONTESTANT_FAIL = "CONTESTANT_FAIL"

export const CHECKOUT_REQUEST = "CHECKOUT_REQUEST"
export const CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS"
export const CHECKOUT_FAIL = "CHECKOUT_FAIL"

export const VIEW_TICKETS_REQUEST = "VIEW_TICKETS_REQUEST"
export const VIEW_TICKETS_SUCCESS = "VIEW_TICKETS_SUCCESS"
export const VIEW_TICKETS_FAIL = "VIEW_TICKETS_FAIL"

export const DASHBOARD_REQUEST = "DASHBOARD_REQUEST"
export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS"
export const DASHBOARD_FAIL = "DASHBOARD_FAIL"
export const DASHBOARD_RESET = "DASHBOARD_RESET"

export const ORDERS_REQUEST = "ORDERS_REQUEST"
export const ORDERS_SUCCESS = "ORDERS_SUCCESS"
export const ORDERS_FAIL = "ORDERS_FAIL"

export const TICKETS_LIST_REQUEST = "TICKETS_LIST_REQUEST"
export const TICKETS_LIST_SUCCESS = "TICKETS_LIST_SUCCESS"
export const TICKETS_LIST_FAIL = "TICKETS_LIST_FAIL"

export const PASSWORD_RESET_REQUEST = "PASSWORD_RESET_REQUEST"
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS"
export const PASSWORD_RESET_FAIL = "PASSWORD_RESET_FAIL"

export const PASSWORD_SET_REQUEST = "PASSWORD_SET_REQUEST"
export const PASSWORD_SET_SUCCESS = "PASSWORD_SET_SUCCESS"
export const PASSWORD_SET_FAIL = "PASSWORD_SET_FAIL"

export const ALL_CONTEST_REQUEST = "ALL_CONTEST_REQUEST"
export const ALL_CONTEST_SUCCESS = "ALL_CONTEST_SUCCESS"
export const ALL_CONTEST_FAIL = "ALL_CONTEST_FAIL"

export const DELIVERYFEE = 6

export const IS_DEV = process.env.REACT_APP_DEV_MODE ? true : false

export const ROOT_URL = window.location.origin ? (window.location.origin) : '';
