import emailjs from 'emailjs-com';

const EmailForm = (data) => {
    const sendEmail = async () => {
        try {
          const templateParams = {
            to_email: 'kidsmulticulturalworldkmw@gmail.com',
            subject: 'Receipt',
            name: data.name,
            candidate: data.candidate,
            price: data.price,
            email: data.email,
            image_base64: data.signature,
          };
    
          await emailjs.send(
            process.env.REACT_APP_SERVICE_ID,
            process.env.REACT_APP_TEMPLATE,
            templateParams,
            process.env.REACT_APP_SECRETE
          );
    
        } catch (error) {
          console.error('Failed to send email:', error);
        } finally {
        }
    };
    sendEmail()
};

export default EmailForm;
