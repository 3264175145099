import React from 'react'

const TermsAndConditions = () => {
  return (
    <div>
        <div className="pt_2 text-muted font_12">
            Kids Multicultural World LLC is not responsible 
            for any accident, or missing items during the cause of 
            attending Class.
        </div>
        <div className="pt_2 text-muted font_12">
            All classes are down via zoom - the links  will be provided 
            24hours after registration. 
        </div>
        <div className="pt_2 text-muted font_12">
            Acting classes are Saturdays 1pm to 2:15pm
            <p className='text-muted font_12'>
                Modeling classes Sunday 1pm to 2:15pm
            </p>
        </div>
        <div className="pt_2 text-muted font_12">
            All payments are final. Not subject to any refunds 
            whatsoever. There are four classes monthly, and payments 
            are required on the 31st of each month.
        </div>
        <div className="pt_2 text-muted font_12">
            Clients are allowed to unsubscribe at the end of each month.
        </div>
    </div>
  )
}

export default TermsAndConditions